<template>
  <div class="bg-Green-primary text-white">
    <footer class="pb-4">
      <div class="p-2 leading-relaxed text-xl">
        <address class="contact-info pt-4 flex flex-col md:flex-row justify-center">
        <ul class="md:pb-8 pb:4 md:px-2">
      <li class="font-bold">Phone: <a class="underline" href="tel:4075672409">407-567-2409</a></li>
      <li>Fax: 407-550-7400</li>
      <li>Email: <a class="underline" href="mailto:info@claritytitlefl.com">info@claritytitlefl.com</a></li>
      <li>3505 Lake Lynda Drive, Suite 200<br>Orlando, FL 32817</li>
    </ul>
    <div class="py-4 md:pt-0">
    <iframe width="360" height="360" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=3505%20Lake%20Lynda%20Drive%2C%20Suite%20200%20Orlando%2C%20FL%2032817&key=AIzaSyAO57KzwYSKXtIcJ-rjjyY9tuuomGY2UrU" allowfullscreen></iframe>
    </div>
    </address>
    <h5 class="text-2xl text-right">&#0169; Clarity Title LLC 2023</h5>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
</style>
