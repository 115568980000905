<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 640 640"
  >
    <path d="M604.732 503.971C442.187 754.202 73.312 636.091 37.3 338.248 17.15 171.545 150.014-15.166 332.52.945c-200.943 187.892-.98 626.122 272.212 503.026z" /></svg>
</template>

<script>
export default {

}
</script>

<style>
</style>
