<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 640 640"
  >
    <path d="M43.252 76.477h118.005l-.13-2.031V21.508c0-11.835 12.296-21.52 27.367-21.52 15.06 0 27.39 9.72 27.39 21.52v52.938l-.142 2.031h200.98l-.142-2.031V21.508c0-11.835 12.295-21.52 27.366-21.52 15.06 0 27.39 9.72 27.39 21.52v52.938l-.141 2.031H596.76c5.362 0 9.732 4.37 9.732 9.733v101.352c0 5.362-4.37 9.732-9.732 9.732H43.253c-5.363 0-9.745-4.37-9.745-9.732V86.21c0-5.363 4.382-9.733 9.745-9.733zm153.545 358.575c-11.834-10.252-13.134-28.181-2.881-40.028 10.252-11.858 28.181-13.157 40.04-2.905l64.831 56.233 105.781-104.4c11.114-11.043 29.103-10.996 40.146.119 11.055 11.114 11.008 29.09-.094 40.146L320.083 507.159c-10.43 10.382-27.272 11.126-38.587 1.335l-84.698-73.442zM35.93 219.734h568.154a2.422 2.422 0 0 1 2.409 2.422V637.59a2.427 2.427 0 0 1-2.41 2.41H35.93c-1.322 0-2.42-1.088-2.42-2.41V222.156a2.425 2.425 0 0 1 2.42-2.422zm46.064 41.28H568.33c2.693 0 4.902 2.327 4.902 4.902v327.37c0 2.575-2.339 4.913-4.902 4.913H77.186c-2.575 0-4.902-2.208-4.902-4.913V270.723c0-5.35 4.359-9.709 9.71-9.709zm361.953-107.883c15.06 0 27.39-9.72 27.39-21.52V78.674l-.153-2.115h-54.45l-.153 2.115v52.937c0 11.835 12.295 21.52 27.366 21.52zm-255.452 0c15.06 0 27.39-9.72 27.39-21.52V78.674l-.153-2.115h-54.462l-.141 2.115v52.937c0 11.835 12.295 21.52 27.366 21.52z" /></svg>
</template>

<script>
export default {

}
</script>

<style>
</style>
