<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2899 3333"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    clip-rule="evenodd"
  >
    <path
      d="M1269 3162c47 0 85 38 85 85s-38 85-85 85H199c-55 0-105-22-141-58s-58-86-58-141V198C0 143 22 93 58 57S144-1 199-1h2442c55 0 105 22 141 58s58 86 58 141v1360c0 47-38 85-85 85s-85-38-85-85V198c0-8-3-15-8-20s-12-8-20-8H200c-8 0-15 3-20 8s-8 12-8 20v2935c0 8 3 15 8 20s12 8 20 8h1070zm1409-1146c-14-13-30-20-48-19-18 0-34 7-47 21l-106 110 297 287 107-112c13-13 18-30 18-48s-7-35-20-47l-201-192zm-556 1076c-39 13-79 25-118 38s-78 26-118 39c-93 30-144 47-155 50s-4-40 19-130l74-283 558-580 297 286-557 580zM857 1114c-47 0-85-38-85-85s38-85 85-85h1127c47 0 85 38 85 85s-38 85-85 85H857zm0 1212c-47 0-86-39-86-87s38-87 86-87h555c47 0 86 39 86 87s-38 87-86 87H857zm0-607c-47 0-85-38-85-85s38-85 85-85h1127c47 0 85 38 85 85s-38 85-85 85H857z"
      fill-rule="nonzero"
    /></svg>
</template>

<script>
export default {

}
</script>

<style>
</style>
